import React, { useEffect, useState } from 'react';
import Layout from '../../components/Layout';
import { tsysMids } from '../../static/tsysMids';

function Index(props) {

    var [mid,setMid] = useState()
    const handleSubmit=(e)=>{
        e.preventDefault()
        if(tsysMids.includes(parseInt(mid))){
            window.open('https://www.mreports.com/portal/start/cwa', '_blank')
        }else{
            window.open('https://www.paymentshub.com/', '_blank')
        }

        // var firstFour = mid.substring(0, 4);
        // switch(firstFour.trim()){
        //     case '3204':
        //     case '4161':
        //     case '4178':
        //     case '4181':
        //     case '4191':
        //         window.open('https://portal.elevateqs.com/', '_blank')
        //         break
        //     case '5808':
        //     case '8929':
        //         window.open('https://www.mreports.com/portal/start/cwa', '_blank')
        //         break
        //     default:
        //         alert('No page found for the provided MID')
        // }
        // console.log(e)
    }
    return (
        <div>
            <Layout>
            <section className="cw-bg-bluegradient text-center py-10 px-5">
                <div className="text-center max-w-2xl mx-auto">
                    <h1 className="text-5xl text-white font-bold pb-4">Web Reporting</h1>
                </div>
            </section>
            <section className="text-center py-14 px-5">
                <form onSubmit={handleSubmit}>
                    <div className="text-center max-w-2xl mx-auto">
                        <div className="flex flex-col">
                            <label className="block text-gray-700" for="mid">Enter your merchant ID number to be taken to the correct page</label>
                            <input onChange={(e)=>setMid(e.target.value)} type="mid" name="mid" id="mid" placeholder="Enter MID" minlength="4" className="rounded w-full px-4 py-3 bg-gray-200 mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none" required/>
                        </div>
                        <button className='flex bg-blue-400 p-2 text-white font-semibold mt-3 text-center w-100'> Take me there </button>
                    </div>
                </form>
            </section>
            {/* <section className="cw-bg-lightblue">
                <div className="px-5 text-center lg:text-left flex lg:justify-between py-5 align-center max-w-5xl mx-auto space-y-3">
                    <h3 className="text-white text-xl font-serif max-w-xl leading-8">Check out our blog for the latest Industry News</h3>
                    <button className="transition ease-in-out delay-150 border-2 border-white p-2 text-white hover:bg-white hover:text-blue-400 font-semibold text-lg">Industry News</button>
                </div>
            </section> */}
            </Layout>
            
        </div>
    );
}

export default Index;