export const tsysMids = [
    580808004806733,
    580808004806881,
    580808004807129,
    580808004807475,
    580808004807913,
    580808004809067,
    892999931395,
    580808004809786,
    892999931387,
    580808004810594,
    892999931379,
    892999931361,
    892999931353,
    892999931338,
    892999931320,
    892999931312,
    580808004811493,
    892999931304,
    892999931296,
    892999931288,
    892999931254,
    892999931239,
    892999931221,
    892999931197,
    892999931189,
    892999931171,
    892999931155,
    892999931122,
    892999931114,
    892999931072,
    892999931064,
    892999931031,
    892999930975,
    580808004811840,
    892999930967,
    892999930942,
    892999930884,
    892999930827,
    892999930736,
    892999930595,
    892999930561,
    892999930439,
    892999930330,
    892999930322,
    892999930140,
    892999930108,
    892999929993,
    892999929902,
    892999929894,
    892999929886,
    892999929787,
    892999929779,
    892999929720,
    892999929738,
    892999929704,
    892999929696,
    580808004817755,
    580808004818266,
    892999929662,
    892999929654,
    892999929647,
    892999929639,
    892999929621,
    892999929415,
    580808004821492,
    892999929399,
    892999929381,
    892999929373,
    892999929357,
    892999929241,
    892999929233,
    892999929217,
    892999929209,
    892999929191,
    892999929183,
    892999929175,
    892999929167,
    892999929159,
    892999929142,
    892999929134,
    892999929126,
    892999929118,
    892999929100,
    892999929092,
    892999929084,
    580808004822581,
    892999929076,
    892999929068,
    580808004823415,
    892999929050,
    580808004823738,
    892999929043,
    892999929027,
    892999929019,
    892999928961,
    892999928953,
    892999928938,
    892999928920,
    892999928888,
    892999928862,
    580808004825261,
    892999928854,
    892999928839,
    892999928805,
    892999928771,
    892999928789,
    892999928730,
    892999928748,
    892999928706,
    892999928722,
    892999928656,
    580808004828950,
    580808004827952,
    580808004829040,
    580808004829248,
    892999928540,
    892999928607,
    892999928532,
    892999928508,
    892999928516,
    892999928524,
    892999928490,
    892999928458,
    892999928466,
    892999928482,
    892999928417,
    892999928409,
    892999928425,
    892999928375,
    892999928391,
    580808004829545,
    892999928359,
    580808004830097,
    580808004830741,
    892999928334,
    580808004831681,
    580808004831947,
    580808004832309,
    580808004832762,
    580808004833307,
    892999928318,
    580808004833943,
    892999928631,
    892999928292,
    580808004836409,
    892999928268,
    892999928243,
    892999928227,
    892999928219,
    892999928185,
    892999928201,
    892999928193,
    892999928169,
    580808004837688,
    580808004837506,
    892999928144,
    892999928136,
    892999928128,
    892999928110,
    892999928011,
    892999928003,
    892999927930,
    892999927831,
    892999927815,
    892999927823,
    580808004839940,
    892999927682,
    580808004841482,
    580808004840666,
    580808004842399,
    580808004844353,
    580808004846028,
    892999927658,
    892999927641,
    580808004849972,
    892999927633,
    892999927625,
    892999927617,
    580808004854824,
    892999927609,
    580808004857322,
    580808004859195,
    892999927526,
    892999927518,
    892999927559,
    580808004861365,
    580808004862223,
    892999927542,
    892999927534,
    580808004866224,
    580808004868014,
    580808004869947,
    580808004871331,
    580808004873196,
    580808004873394,
    892999927476,
    580808004875118,
    892999927468,
    892999927450,
    580808004878575,
    580808004878807,
    580808004880084,
    580808004879136,
    580808004880704,
    892999927427,
    580808004881413,
    580808004883104,
    892999927393,
    580808004884169,
    580808004884334,
    580808004884599,
    892999927377,
    892999927369,
    580808004887113,
    580808004888053,
    580808004888152,
    580808004889473,
    580808004891453,
    892999927328,
    580808004893244,
    892999927302,
    580808004893418,
    580808004893632,
    580808004893947,
    580808004895447,
    580808004894358,
    580808004894853,
    580808004896130,
    580808004896916,
    892999927286,
    580808004897799,
    892999927278,
    580808004898771,
    580808004899001,
    580808004898847,
    892999927245,
    892999927237,
    580808004904470,
    892999927195,
    892999927161,
    580808004906004,
    892999927179,
    892999927153,
    580808004906715,
    580808004907515,
    580808004908414,
    580808004909404,
    580808004909495,
    892999927120,
    580808004909685,
    580808004910949,
    580808004910378,
    892999927088,
    580808004914867,
    580808004915690,
    892999927070,
    892999927062,
    580808004919551,
    892999927039,
    580808004920492,
    580808004919429,
    580808004920997,
    580808004922308,
    580808004921607,
    580808004924973,
    580808004923991,
    892999926981,
    580808004932455,
    892999926973,
    580808004941001,
    580808004941183,
    580808004941878,
    580808004941480,
    892999926940,
    892999926924,
    892999926916,
    580808004951497,
    892999926908,
    892999926890,
    580808004957221,
    580808004956967,
    580808004956801,
    580808004962650,
    892999926874,
    580808004962742,
    892999926866,
    892999926833,
    892999926841,
    580808004967782,
    892999926817,
    580808004974861,
    892999926809,
    892999926759,
    892999926767,
    892999926734,
    580808004977963,
    580808004977054,
    892999926742,
    580808004985487,
    580808004985842,
    580808004989943,
    580808004990917,
    892999926718,
    892999926692,
    580808004995163,
    892999926684,
    580808004995320,
    580808004995536,
    892999926668,
    580808004995841,
    892999926676,
    892999926650,
    580808004998704,
    580808005000484,
    892999926627,
    580808005001110,
    892999926619,
    892999926601,
    580808005006101,
    580808005006762,
    580808005011390,
    892999926544,
    580808005015193,
    892999926510,
    580808005017397,
    580808005017207,
    892999926452,
    580808005019815,
    580808005022645,
    892999926429,
    580808005038674,
    580808005038914,
    580808005038518,
    580808005038450,
    580808005037478,
    580808005036595,
    580808005035803,
    580808005035100,
    580808005034491,
    580808005033972,
    580808005033543,
    580808005033204,
    580808005032958,
    580808005032792,
    580808005032727,
    580808005031745,
    892999926403,
    580808005039672,
    892999926395,
    580808005041496,
    892999926387,
    580808005056494,
    580808005057609,
    580808005059076,
    892999926320,
    580808005060975,
    580808005060918,
    580808005061346,
    580808005062096,
    892999926296,
    892999926288,
    580808005063201,
    892999926262,
    580808005068390,
    892999926247,
    580808005069059,
    892999926239,
    892999926213,
    580808005071667,
    580808005071790,
    892999926189,
    580808005076633,
    580808005077359,
    580808005077854,
    892999926171,
    892999926163,
    892999926130,
    892999926148,
    580808005079132,
    580808005078449,
    892999926122,
    892999926114,
    892999926080,
    580808005080775,
    892999926015,
    892999926023,
    892999925926,
    892999925892,
    892999925918,
    892999925884,
    892999925868,
    892999925801,
    892999925785,
    892999925751,
    892999925710,
    892999925694,
    892999925686,
    892999925660,
    892999925629,
    892999925595,
    892999925561,
    892999925538,
    892999925520,
    892999925504,
    892999925447,
    892999925454,
    892999925439,
    892999925413,
    892999925421,
    580800000001032,
    892999925405,
    892999925322,
    892999925348,
    892999925330,
    892999925306,
    892999925280,
    892999925231,
    892999925256,
    892999925264,
    892999925199,
    892999925215,
    892999925207,
    892999925165,
    892999925140,
    892999925124,
    892999925108,
    892999925074,
    892999925066,
    892999925058,
    892999925033,
    892999925025,
    892999924978,
    892999924960,
    892999924945,
    892999924911,
    892999924853,
    892999924796,
    892999924770,
    892999924754,
    892999924739,
    892999924713,
    892999924697,
    892999924663,
    892999924655,
    892999924622,
    892999924572,
    892999924531,
    892999924523,
    892999924465,
    892999924408,
    892999924366,
    892999924390,
    892999924382,
    892999924374,
    892999924333,
    892999924317,
    892999924341,
    892999924325,
    892999924291,
    892999924259,
    892999924267,
    892999924226,
    892999924192,
    892999924176,
    892999924168,
    892999924143,
    892999924135,
    892999924119,
    892999924085,
    892999924044,
    892999924051,
    892999923996,
    892999924002,
    892999923988,
    892999923947,
    892999923970,
    892999923962,
    892999923913,
    892999923905,
    892999923897,
    892999923863,
    892999923855,
    892999923848,
    892999923806,
    892999923822,
    892999923665,
    892999923657,
    892999923632,
    892999923590,
    892999923582,
    892999923533,
    892999923566,
    892999923541,
    892999923491,
    892999923475,
    892999923467,
    892999923392,
    892999923434,
    892999923384,
    892999923319,
    892999923251,
    892999923236,
    892999923202,
    892999923186,
    892999923145,
    892999923137,
    892999923004,
    892999922915,
    892999922881,
    892999922865,
    892999922840,
    892999922832,
    892999922824,
    892999922816,
    892999922758,
    892999922790,
    892999922733,
    892999922691,
    892999922675,
    892999922634,
    892999922592,
    892999922584,
    892999922568,
    892999922493,
    892999922428,
    892999922394,
    892999922329,
    892999922295,
    892999922311,
    892999922303,
    892999922287,
    892999922246,
    892999922212,
    892999922204,
    892999922162,
    892999922154,
    892999922139,
    892999922113,
    892999922063,
    892999922022,
    892999922014,
    892999921982,
    892999921974,
    892999921933,
    892999921891,
    892999921834,
    892999921826,
    892999921735,
    892999921701,
    892999921685,
    892999921677,
    892999921628,
    892999921602,
    892999921560,
    892999921552,
    892999921529,
    892999921354,
    892999921396,
    892999921347,
    892999921313,
    892999921305,
    892999921248,
    892999921214,
    892999921206,
    892999921180,
    892999921149,
    892999921156,
    892999921107,
    892999921008,
    892999920992,
    892999920984,
    892999920968,
    892999920927,
    892999920919,
    892999920901,
    892999920893,
    892999920877,
    892999920836,
    892999920752,
    892999920745,
    892999920687,
    892999920679,
    892999920612,
    892999920570,
    892999920315,
    892999920331,
    892999920323,
    892999920539,
    892999920513,
    892999920505,
    892999920497,
    892999920489,
    892999920471,
    892999920463,
    892999920455,
    892999920448,
    892999920430,
    892999920422,
    892999920414,
    892999920406,
    892999920398,
    892999920380,
    892999920372,
    892999920364,
    892999920356,
    892999920349,
    892999920562,
    892999920273,
    892999920208,
    892999920216,
    892999920083,
    892999920117,
    892999920109,
    892999920091,
    892999920026,
    892999919994,
    892999919911,
    892999919903,
    892999919861,
    892999919804,
    892999919697,
    892999919630,
    892999919598,
    892999919515,
    892999919416,
    892999919408,
    892999919382,
    892999919366,
    892999919309,
    892999919317,
    892999919283,
    892999919226,
    892999919184,
    892999919192,
    892999919127,
    892999919044,
    892999919010,
    892999918954,
    892999918962,
    892999918913,
    892999918905,
    892999918897,
    892999918921,
    892999918889,
    892999918814,
    892999918806,
    892999918798,
    892999918848,
    892999918756,
    892999918764,
    892999918665,
    892999918657,
    892999918632,
    892999918582,
    892999918541,
    892999918533,
    892999918558,
    892999918467,
    892999918442,
    892999918426,
    892999918400,
    892999918392,
    892999918319,
    892999918301,
    892999918293,
    892999918285,
    892999918277,
    892999918327,
    892999918335,
    892999918244,
    892999918194,
    892999918186,
    892999918178,
    892999918137,
    892999918095,
    892999918012,
    892999918004,
    892999917915,
    892999917907,
    892999917816,
    892999917659,
    892999917634,
    892999917592,
    892999917568,
    892999917519,
    892999917501,
    892999917493,
    892999917485,
    892999917410,
    892999917386,
    892999917337,
    892999917329,
    892999917311,
    892999917303,
    892999917253,
    892999917246,
    892999917238,
    892999917188,
    892999917204,
    892999917147,
    892999917139,
    892999917113,
    892999917105,
    892999917097,
    892999917006,
    892999916859,
    892999916909,
    892999916883,
    892999916875,
    892999916867,
    892999916735,
    892999916719,
    892999916677,
    892999916545,
    892999916560,
    892999916537,
    892999916438,
    892999916412,
    892999916404,
    892999916388,
    892999916305,
    892999916321,
    892999916313,
    892999916271,
    892999915984,
    892999916008,
    892999915992,
    892999915976,
    892999915950,
    892999915927,
    892999915877,
    892999915786,
    892999915646,
    892999915588,
    892999915547,
    892999915513,
    892999915489,
    892999915471,
    892999915398,
    892999915372,
    892999915331,
    892999915323,
    892999915240,
    892999915133,
    892999915059,
    892999915034,
    892999915026,
    892999915000,
    892999914938,
    892999914904,
    892999914805,
    892999914615,
    892999914508,
    892999914482,
    892999914441,
    892999914417,
    892999914409,
    892999914391,
    892999914383,
    892999914292,
    892999914268,
    892999914250,
    892999914243,
    892999914235,
    892999914227,
    892999914219,
    892999914201,
    892999914193,
    892999914185,
    892999914177,
    892999914169,
    892999914151,
    892999914144,
    892999914136,
    892999914128,
    892999914102,
    892999914094,
    892999914086,
    892999914078,
    892999914060,
    892999914052,
    892999914045,
    892999914037,
    892999914003,
    892999914011,
    892999914029,
    892999913997,
    892999913989,
    892999913971,
    892999913963,
    892999913955,
    892999913948,
    892999913856,
    892999913831,
    892999913781,
    892999913773,
    892999913757,
    892999913732,
    892999913617,
    892999913500,
    892999913476,
    892999913427,
    892999913294,
    892999913245,
    892999913153,
    892999913070,
    892999913021,
    892999912973,
    892999912825,
    892999912817,
    892999912775,
    892999912643,
    892999912627,
    892999912569,
    892999912429,
    892999912361,
    892999912395,
    892999912387,
    892999912379,
    892999912189,
    892999912148,
    892999911926,
    892999911900,
    892999911769,
    892999911546,
    892999911553,
    892999911512,
    892999911363,
    892999911348,
    892999911306,
    892999911272,
    892999911264,
    892999910852,
    892999910795,
    892999910720,
    892999910696,
    892999910639,
    892999910621,
    892999910571,
    892999910522,
    892999910480,
    892999910464,
    892999910472,
    892999910423,
    892999910407,
    892999910324,
    892999910076,
    892999909987,
    892997766959,
    892997502461,
    892995527437,
    892996225114,
    892995766936,
    892995634217,
    892993513116,
    892993256765,
    892976865745,
    892944271276,
    892991764810,
    892989301961,
    892989297912,
    892988812919,
    892987666498,
    892982677953,
    892983038726,
    892979874926,
    892979600719,
    892976007421,
    892972889624,
    892969429150,
    892969150707,
    892967599194,
    892967166747,
    892966735203,
    892965453824,
    892964431466,
    892962895159,
    892959863507,
    892959751199,
    892959599481,
    892959116096,
    892958789331,
    892956365191,
    892955439153,
    892955009774,
    892954099313,
    892953161023,
    892952344125,
    892952307932,
    892952220275,
    892950749408,
    892948824328,
    892946768717,
    892945748751,
    892944314084,
    892944271286,
    892944244828,
    892944397477,
    892943946753,
    892943577236,
    892943210218,
    892942115442,
    892942044824,
    892941262906,
    892940737874,
    892939888892,
    892939327891,
    892939327222,
    892938924722,
    892938557001,
    892938493546,
    892933291341,
    892933121704,
    892930117457,
    892928575649,
    892929156043,
    892928961559,
    892927612849,
    892927691504,
    892924043717,
    892923375987,
    892937494867,
    892922237707,
    892921182005,
    892920625079,
    892920881037,
    892919846254,
    892919011834,
    892918365033,
    892917465883,
    892917128408,
    892916670376,
    892914945960,
    892914834172,
    892914796736,
    892914692851,
    892912946747,
    892912630705,
    892912391993,
    892911827153,
    892911647296,
    892910737387,
    892910380154,
    892909398546,
    892909340894,
    892909128224,
    892908738007,
    892908607939,
    892907872260,
    892903999687,
    892901600204,
    892901724012,
    892901433671,
    892901029487,
    892900726695,
    892900861450,
    892900387456,
    892900221895,
    892900057620,
    580800000000026,
    580800000000034,
    580800000000042,
    580800000003145,
    580800000000067,
    580800000000059,
    580800000000075
    ]
